import { Container, Row, Col, Tab } from "react-bootstrap";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import meter1 from "../assets/img/aws1.png";
import meter2 from "../assets/img/Terraform.png";
import meter3 from "../assets/img/gitlab1.png";
import meter4 from "../assets/img/docker1.png";
import meter5 from "../assets/img/k8s1.png";
import meter6 from "../assets/img/react1.png";
import meter7 from "../assets/img/psql1.png";
import meter8 from "../assets/img/mysql1.png";
import meter9 from "../assets/img/vmware1.png";
import meter10 from "../assets/img/ansible1.png";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';




export const Projects = () => {
  
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
 
  return (
    <section className="tech-stack" id="tech-stack">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Tech - Stack</h2>
                <p>Below we present examples of technologies that I use in my projects:</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Carousel removeArrowOnDeviceType={["desktop", "tablet", "mobile"]} autoPlay={true} autoPlaySpeed={3500} responsive={responsive} infinite={true} className="owl-carousel owl-theme tech-stack-slider">
                            <div className="item">
                                <img src={meter1} alt="Image" />                                
                            </div>
                            <div className="item">
                                <img src={meter2} alt="Image" />                                
                            </div>
                            <div className="item">
                                <img src={meter3} alt="Image" />                                
                            </div>
                            <div className="item">
                                <img src={meter4} alt="Image" />                                
                            </div>
                            <div className="item">
                                <img src={meter5} alt="Image" />                                
                            </div>
                            <div className="item">
                                <img src={meter6} alt="Image" />                                
                            </div>
                            <div className="item">
                                <img src={meter7} alt="Image" />                                
                            </div>
                            <div className="item">
                                <img src={meter8} alt="Image" />                                
                            </div>
                            <div className="item">
                                <img src={meter9} alt="Image" />                                
                            </div>
                            <div className="item">
                                <img src={meter10} alt="Image" />                                
                            </div>
                        </Carousel>                    
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
