import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png"

export const Aboutme = () => {
  
  return (
    <section className="Aboutme" id="Aboutme">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>About me</h2>
                        <p>My beginnings with IT started 15 years ago as a network administrator / sysadmin. Continuous personal development has led me to where I live. At the very beginning there were physical servers, then the target datacentre such as OVH became in line with the trend. Until cloud solutions have led to a total advantage over the transitional datacenter. I have built a lot of network and Cloud infrastructures for my clients. I take an active part in the process of building web applications. Continuous support of development teams in building CI / CD space using a version control tool such as Gitlab. He deals with the broadly understood Devopsing from simple network infrastructures to complex web applications based on the AWS cloud.</p>
                        <p>Are you looking for professional support in your Project? <br></br> Maybe you don't want to spend a lot of money on big devops Teams? <br></br>Write to me ... And we will certainly be able to help you in the implementation of the <b>"Great"</b> Project.</p>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}
